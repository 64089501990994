import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from "vuex-persistedstate";

Vue.use(Vuex);

const store = new Vuex.Store({
    state: {
        card: {
            realName: '',
            idCard: '',
            idCardFront: '',
            idCardBack: ''
        },
    },
    mutations: {
        setCard(state, value) {
            state.card = value;
        }
    },
    plugins: [
        createPersistedState({
            storage: window.sessionStorage,
        }),
    ],
});

export default store;