<template>
    <div class="flexPage darkPage">
        <navbar :params="pageInfo" />
        <div class="title">{{ $t('other.translate83') }}</div>
        <div class="titles">{{ $t('other.translate84') }}</div>
        <!-- 身份证正面 -->
        <van-uploader class="mt30" name="file" :after-read="onAfterReadFront">
            <div class="icon_id_face">
                <template v-if="formData.idCardFront">
                    <img :src="formData.idCardFront" alt="" style="width: 90.8397vw;height: 45.8015vw;border-radius: 2.0356vw;">
                </template>
                <template v-else>
                    <img src="../../assets/img/icon_id_face.png" alt="">
                    <div class="content" :lang="$i18n.locale">
                        <img src="../../assets/img/pai.png" alt="" style="width: 6.1069vw;height: 6.1069vw;margin-right: 1.2723vw">
                        <span>{{ $t('other.translate85') }}</span>
                    </div>
                </template>
            </div>
        </van-uploader>
        <!-- 身份证反面 -->
        <van-uploader class="mt80" name="file" :after-read="onAfterReadBack">
            <div class="icon_id_face">
                <template v-if="formData.idCardBack">
                    <img :src="formData.idCardBack" alt="" style="width: 90.8397vw;height: 45.8015vw;border-radius: 2.0356vw;">
                </template>
                <template v-else>
                    <img src="../../assets/img/icon_id_back.png" alt="">
                    <div class="content" :lang="$i18n.locale">
                        <img src="../../assets/img/pai.png" alt="" style="width: 6.1069vw;height: 6.1069vw;margin-right: 1.2723vw">
                        <span>{{ $t('other.translate85') }}</span>
                    </div>
                </template>
            </div>
        </van-uploader>
        <div class="btn" @click="save">{{ $t('other.translate89') }}</div>
        <!-- <div class="form-boxes">
            <van-field :label="$t('form.account.username')" v-model="form.realName" :border="false" input-align="right"
                @blur="blurRealName" :placeholder="$t('form.account.please_enter_your_real_name')" />
        </div>
        <div class="form-boxes">
            <van-field :label="$t('form.account.card_id')" v-model="form.idCard" :border="false" input-align="right"
                @blur="blurIdCard" :placeholder="$t('form.account.please_enter_your_number')" />
        </div>
        <div class="form-boxes">
            <van-field :label="$t('form.account.id_photo')" disabled :border="false" input-align="right" />
            <span @click="$mts.goto('identity')"> {{ verify ? '已上传' : $t('form.account.not_uploaded') }}</span>
        </div>

        <div class="audit-tips">身份验证：
            <span v-if="user.realAuditing == 1">审核中</span>
            <span v-else-if="user.realVerified == 1">已认证</span>
            <span v-else-if="user.realVerified == 0">未认证</span>
        </div>
        <div class="p_absolute">
            <van-tabbar>
                <van-tabbar-item>
                    <van-button @click="save" size="large" color="#3861FB">{{ $t('form.account.save') }}</van-button>
                </van-tabbar-item>
            </van-tabbar>
        </div> -->
    </div>
</template>
<script>
import navbar from '@/views/modules/navbar.vue';
import store from './store/common';

export default {
    data() {
        return {
            pageInfo: {
                title: '',
                border: false,
                customTitle: this.$t('other.translate86'),
                navbarStyle: 'normal',
                backname: "my",
            },
            user: {},
            verify: false,
            form: {
                realName: '',
                idCard: '',
            },
            formData: {
                idCardFront: '',
                idCardBack: ''
            }
        }
    },
    mounted() {
        this.form = store.state.card;
        this.getMember();
    },
    created() {
        this.completeVerification();
    },
    components: { navbar },
    methods: {
        blurRealName() {
            store.commit('setCard', { ...store.state.card, ...this.form });
        },
        blurIdCard() {
            store.commit('setCard', { ...store.state.card, ...this.form });
        },
        // 检验是否验证成功
        completeVerification() {
            if (!store.state.card) return;
            if (store.state.card.idCardFront && store.state.card.idCardBack) {
                this.verify = true;
            } else {
                this.verify = false;
            }
        },
        //获取个人安全信息
        getMember() {
            this.$nextTick(() => {
                this.$http.post(this.host + '/uc/approve/security/setting').then((response) => {
                    var resp = response.body;
                    if (resp.code == 0) {
                        this.user = resp.data;
                    } else {
                        this.$toast(resp.message);
                    }
                });
            });
        },
        // 提交验证
        save() {
            // 身份证
            // const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/;
            // if (!this.form.realName) return this.$toast(this.$t('form.account.please_enter_your_real_name'));
            // if (!this.form.idCard) return this.$toast(this.$t('form.account.please_enter_your_number'));
            // if (!reg.test(this.form.idCard)) return this.$toast('请输入正确的身份证号');
            // if (!this.verify) return this.$toast('请确认是否已经上传全部图片');
            let param = {};
            // param['realName'] = this.form.realName;
            // param['idCard'] = this.form.idCard;
            param['idCardFront'] = this.formData.idCardFront;
            param['idCardBack'] = this.formData.idCardBack;
            console.log("popooo", param);
            // param['handHeldIdCard'] = '';
            this.$http.post(this.host + '/uc/approve/real/name', param).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.$toast(this.$t('other.translate157'));
                    this.$mts.goto('my');
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        onAfterReadFront(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            this.$http.post(this.host + '/uc/upload/local/image', formData).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.formData.idCardFront = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
        onAfterReadBack(file) {
            const formData = new FormData();
            formData.append('file', file.file);
            this.$http.post(this.host + '/uc/upload/local/image', formData).then((response) => {
                var resp = response.body;
                if (resp.code == 0) {
                    this.formData.idCardBack = resp.data;
                } else {
                    this.$toast(resp.message);
                }
            });
        },
    }
}
</script>

<style>
.btn {
    width: 87.2774vw;
    height: 11.1959vw;
    margin: 0 auto;
    border-radius: 2.0356vw;
    background: #000000;
    font-family: Inter;
    font-size: 4.0712vw;
    font-weight: 600;
    line-height: 11.1959vw;
    letter-spacing: -0.02em;
    text-align: center;
    margin-top: 18.3206vw;
    color: #fff
}

.mt30 {
    width: 100%;
    margin-top: 30px;
}

.mt80 {
    margin-top: 20.3562vw;
}

/* .tips {
    margin-top: 15px;
    text-align: center;
    font-size: 13.9998px;
    color: #969799;
} */

.icon_id_face {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
}

.content:lang(zh-CN) {
    position: absolute;
    top: 20.3562vw;
    right: 38.1679vw;
    display: flex;
    align-items: center;
    color: #fff
}

.content:lang(en-US) {
    position: absolute;
    top: 20.3562vw;
    right: 30.5344vw;
    display: flex;
    align-items: center;
    color: #fff
}

.icon_id_face img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.title {
    font-family: Inter;
    font-size: 4.0712vw;
    font-weight: 600;
    line-height: 5.6997vw;
    letter-spacing: -0.02em;
    margin-top: 10.1781vw
}

.titles {
    font-family: Inter;
    font-size: 3.5623vw;
    font-weight: 400;
    line-height: 5.6997vw;
    letter-spacing: -0.02em;
    color: #808080;
    margin-top: 4.0712vw
}

.flexPage {
    width: 100%;
    padding: 0 4.0712vw;

}
</style>